import { createStore } from 'vuex';
import userStore from '@/store/modules/userStore.js';
import idSave from '@/store/modules/idSave.js';
import constants from '@/store/modules/constants.js';
import path from '@/store/modules/path.js';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  modules: {
    userStore: userStore,
    idSave: idSave,
    constants: constants,
    path: path,
  },
  plugins: [
    // createPersistedState({
    //   paths: ['userStore'],
    //   storage: window.sessionStorage,
    // }),
    createPersistedState({ paths: ['userStore', 'idSave'] }),
  ],
});

export default store;
