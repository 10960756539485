<template>
  <router-view v-slot="{ Component }">
    <!-- <keep-alive v-if="$store.getters['userStore/isLogin'] && keepCache" :max="maxCache">
              <component :is="Component" :key="route.path + routeKey" />
            </keep-alive> -->
    <component :is="Component" />
  </router-view>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style></style>
