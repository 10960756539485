import VueSweetalert2 from 'vue-sweetalert2';

const defaultOptions = {
  confirmButtonText: '확인',
  cancelButtonText: '취소',
};

class SweetalertPlugin extends VueSweetalert2 {
  static install(vue, options = defaultOptions) {
    super.install(vue, options);

    let swalFunction;

    if (vue.config?.globalProperties?.$swal) {
      swalFunction = vue.config.globalProperties.$swal;
    } else {
      swalFunction = vue.prototype.$swal;
    }

    const defaultToastOptions = {
      toast: true,
      position: 'top-end',
      timer: 1500,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', swalFunction.stopTimer);
        toast.addEventListener('mouseleave', swalFunction.resumeTimer);
      },
    };

    const defaultSuccessOptions = {
      icon: 'success',
      text: '완료되었습니다.',
    };

    const defaultWarningOptions = {
      icon: 'warning',
      text: '경고가 발생했습니다.',
    };

    const defaultErrorOptions = {
      icon: 'error',
      text: '오류가 발생했습니다.',
    };

    const defaultConfirmOptions = {
      icon: 'question',
      text: '사용하시겠습니까?',
      showCancelButton: true,
    };

    swalFunction['confirm'] = (option = {}) => {
      let assignOption;
      if (typeof option === 'object') {
        assignOption = Object.assign({}, defaultConfirmOptions, option);
      } else if (typeof option === 'string') {
        assignOption = Object.assign({}, defaultConfirmOptions, { text: option });
      }

      return swalFunction(assignOption);
    };

    swalFunction['success'] = (option = {}) => {
      const assignOption = Object.assign({}, defaultSuccessOptions, option);
      return swalFunction(assignOption);
    };

    swalFunction['warning'] = (option = {}) => {
      const assignOption = Object.assign({}, defaultWarningOptions, option);
      return swalFunction(assignOption);
    };

    swalFunction['error'] = (option = {}) => {
      let assignOption;
      if (typeof option === 'object') {
        assignOption = Object.assign({}, defaultErrorOptions, option);
      } else if (typeof option === 'string') {
        assignOption = Object.assign({}, defaultErrorOptions, { text: option });
      }
      return swalFunction(assignOption);
    };

    swalFunction['successToast'] = (option = {}) => {
      let assignOption;
      if (typeof option === 'object') {
        assignOption = Object.assign({}, defaultToastOptions, option);
      } else if (typeof option === 'string') {
        assignOption = Object.assign({}, defaultToastOptions, { text: option });
      }
      return swalFunction['success'](assignOption);
    };

    swalFunction['warningToast'] = (option = {}) => {
      let assignOption;
      if (typeof option === 'object') {
        assignOption = Object.assign({}, defaultToastOptions, option);
      } else if (typeof option === 'string') {
        assignOption = Object.assign({}, defaultToastOptions, { text: option });
      }
      return swalFunction['warning'](assignOption);
    };

    swalFunction['errorToast'] = (option = {}) => {
      let assignOption;
      if (typeof option === 'object') {
        assignOption = Object.assign({}, defaultToastOptions, option);
      } else if (typeof option === 'string') {
        assignOption = Object.assign({}, defaultToastOptions, { text: option });
      }
      return swalFunction['error'](assignOption);
    };
  }
}

export default SweetalertPlugin;
