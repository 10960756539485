import Home from '@/router/views/Home.vue';

export default {
  path: '/board',
  name: 'board',
  component: Home,
  children: [
    {
      path: ':boardName/list',
      component: () => import('@components/board/BoardList.vue'),
    },
    {
      path: ':boardName/view/:id',
      component: () => import('@components/board/BoardView.vue'),
    },
    {
      path: ':boardName/write',
      component: () => import('@components/board/BoardWrite.vue'),
    },
    {
      path: ':boardName/write/:id',
      component: () => import('@components/board/BoardWrite.vue'),
    },
    {
      path: 'department-work/list',
      component: () => import('@components/board/DepartmentWorkBoardList.vue'),
    },
    {
      path: 'department-work/view/:id',
      component: () => import('@components/board/DepartmentWorkBoardView.vue'),
    },
    {
      path: 'department-work/write',
      component: () => import('@components/board/DepartmentWorkBoardWrite.vue'),
    },
    {
      path: 'department-work/write/:id',
      component: () => import('@components/board/DepartmentWorkBoardWrite.vue'),
    },
    {
      path: 'work-report/list',
      component: () => import('@components/board/DepartmentWorkBoardList.vue'),
    },
    {
      path: 'work-report/view/:id',
      component: () => import('@components/board/DepartmentWorkBoardView.vue'),
    },
    {
      path: 'work-report/write',
      component: () => import('@components/board/DepartmentWorkBoardWrite.vue'),
    },
    {
      path: 'work-report/write/:id',
      component: () => import('@components/board/DepartmentWorkBoardWrite.vue'),
    },
  ],
};
