const idSave = {
  namespaced: true,
  state: {
    saveId: false,
    id: '',
    domain: '',
  },
  mutations: {
    idSaving: function (state, payload) {
      if (payload.saveId) {
        state.id = payload.id;
        state.domain = payload.domain;
        state.saveId = true;
      } else {
        state.id = '';
        state.saveId = false;
      }
    },
  },
  getters: {
    isSave(state) {
      return state.saveId;
    },
    getSavedId(state) {
      return state.saveId ? state.id : '';
    },
    getDomain(state){
      return state.domain? state.domain:'rainbownonsul.com';
    }
  },
};

export default idSave;
