export default {
  methods: {
    focus(element) {
      element.focus();
      let targetTop = element.getBoundingClientRect().top - 70;
      if (targetTop < 10) {
        window.scrollBy(0, targetTop);
      }
    },
  },
};
