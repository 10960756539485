import {deepFreeze} from '@utils/objectUtil';
import {cloneDeep} from 'lodash';

const constant = {
  bookGroup: [
    {name: '워크북', value: 'WORKBOOK'},
    {name: '단행본', value: 'PAPERBACK'},
    {name: '부교재', value: 'ADJUNCT_BOOK'},
    {name: '기타', value: 'SUPPLIES'},
  ],
  bookType: {
    WORKBOOK: [
      {
        name: '초등부',
        child: [
          {name: '1학년', value: 'ELEMENTARY_1'},
          {name: '2학년', value: 'ELEMENTARY_2'},
          {name: '3학년', value: 'ELEMENTARY_3'},
          {name: '4학년', value: 'ELEMENTARY_4'},
          {name: '5학년', value: 'ELEMENTARY_5'},
          {name: '6학년', value: 'ELEMENTARY_6'},
        ],
      },
      {
        name: '초등부 3%',
        child: [
          {name: '5학년', value: 'ELEMENTARY_5_3_PERCENT'},
          {name: '6학년', value: 'ELEMENTARY_6_3_PERCENT'},
        ],
      },
      {
        name: '중등부',
        child: [
          {name: '1학년', value: 'MIDDLE_1'},
          {name: '2학년', value: 'MIDDLE_2'},
          {name: '3학년', value: 'MIDDLE_3'},
        ],
      },
      //{ name: '고등부', child: [{ name: '1학년', value: 'HIGH_1' }] },
      {name: '고등부', value: 'HIGH'},
      {name: '특강 워크북', value: 'SPECIAL_LECTURE'},
    ],
    PAPERBACK: [{name: '단행본', value: 'PAPERBACK'}],
    ADJUNCT_BOOK: [
      {name: '관련도서', value: 'RELATED_BOOKS'},
      {name: '브로셔, 프로그램안내', value: 'BROCHURE_PROGRAM_INF'},
      {name: '기타', value: 'ETC'},
      //{ name: '경시관련', value: 'CONTEST' }
      //{ name: '진단평가', value: 'DIAGNOSTIC_EVALUATION' },
    ],
    SUPPLIES: [{name: '학원비품', value: 'SUPPLIES'}],
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
const selectData: object[] = [];
const groupData: object[] = [];
// eslint-disable-next-line @typescript-eslint/ban-types
const dictData: object = {};

const bookTypeList = constant.bookGroup.flatMap(group => constant.bookType[group.value])

for (const bookGroup of constant.bookGroup) {
  groupData[bookGroup.value] = [];
  for (const bookTypeAll of constant.bookType[bookGroup.value]) {
    if (Array.isArray(bookTypeAll?.child)) {
      for (const bookType of bookTypeAll.child) {
        groupData[bookGroup.value] .push({
          name: '[' + bookTypeAll.name + ']' + ' ' + bookType.name,
          value: bookType.value,
        });
      }
    } else {
      if (bookTypeAll?.value) {
        groupData[bookGroup.value].push({
          name: bookTypeAll.name,
          value: bookTypeAll.value,
        });
      }
    }
  }
}

for (const bookTypeAll of bookTypeList) {
  if (Array.isArray(bookTypeAll?.child)) {
    for (const bookType of bookTypeAll.child) {
      dictData[bookType.value] = '[' + bookTypeAll.name + ']' + ' ' + bookType.name;
      selectData.push({
        name: '[' + bookTypeAll.name + ']' + ' ' + bookType.name,
        value: bookType.value,
      });
    }
  } else {
    if (bookTypeAll?.value) {
      dictData[bookTypeAll.value] = bookTypeAll.name;
      selectData.push({
        name: bookTypeAll.name,
        value: bookTypeAll.value,
      });
    }
  }
}

constant['bookTypeList'] = cloneDeep(bookTypeList);
constant['bookTypeSelect'] = selectData;
constant['bookTypeObject'] = dictData;
constant['bookTypeGroupList'] = groupData;

export default deepFreeze(constant);
