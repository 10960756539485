import Home from '@/router/views/Home.vue';

export default {
  path: '/intranet-management',
  name: 'intranet-management',
  component: Home,

  children: [
    {
      path: 'user/list',
      component: () => import('@components/intranet-management/user/UserList.vue'),
    },
    {
      path: 'user/view/:id',
      component: () => import('@components/intranet-management/user/UserView.vue'),
    },
    {
      path: 'authority',
      component: () => import('@components/intranet-management/authority/Authority.vue'),
    },
    {
      path: 'equipment/list',
      component: () => import('@components/intranet-management/equipment/EquipmentList.vue'),
    },
    {
      path: 'equipment/write/:id',
      name: 'EquipmentWrite',
      component: () => import('@components/intranet-management/equipment/EquipmentWrite.vue'),
    },
    {
      path: 'equipment/history/:propertyId',
      name: 'EquipmentHistory',
      component: () => import('@components/intranet-management/equipment/EquipmentHistory.vue'),
    },
    {
      path: 'equipment/total',
      name: 'EquipmentTotal',
      component: () => import('@components/intranet-management/equipment/EquipmentTotal.vue'),
    },
    {
      path: 'branch/list',
      component: () => import('@components/intranet-management/branch/BranchList.vue'),
    },
    {
      path: 'branch/write',
      component: () => import('@components/intranet-management/branch/BranchWrite.vue'),
    },
    {
      path: 'branch/write/:id',
      component: () => import('@components/intranet-management/branch/BranchWrite.vue'),
    },
  ],
};
