import {
  fileWhitelist,
  imageFileWhitelist,
  documentFileWhitelist
} from '@constants/fileWhitelist';
import bookType from '@constants/bookType';
import branchInfo from '@constants/branchInfo';

const constant = {
  namespaced: true,
  state: {
    fileWhitelist,
    imageFileWhitelist,
    documentFileWhitelist,
    bookType,
    branchInfo,
  },
  getters: {
    fileWhitelist: state => {
      return state.fileWhitelist;
    },
    fileWhitelistExtension: state => {
      return state.fileWhitelist.extension;
    },
    fileWhitelistMediaTypes: state => {
      return state.fileWhitelist.mediaTypes;
    },

    imageFileWhitelist: state => {
      return state.imageFileWhitelist;
    },
    imageFileWhitelistExtension: state => {
      return state.imageFileWhitelist.extension;
    },
    imageFileWhitelistMediaTypes: state => {
      return state.imageFileWhitelist.mediaTypes;
    },

    bookType: state => {
      return state.bookType.bookTypeList;
    },
    bookGroup: state => {
      return state.bookType.bookGroup;
    },
    bookTypeByBookGroup: (state) => (group) => {
      return state.bookType.bookTypeGroupList[group];
    },
    bookTypeSelect: state => {
      return state.bookType.bookTypeSelect;
    },
    bookTypeObject: state => {
      return state.bookType.bookTypeObject;
    },
    branchInfo: state => {
      return state.branchInfo;
    },
  },
};

export default constant;
