import { createApp, defineAsyncComponent } from 'vue';
import router from '@/router/index';
import store from '@/store/index';
import axios from '@/plugins/axios';
import axiosReq from '@/plugins/axios-req';
import mitt from 'mitt';
import App from '@/App.vue';
import { numberOnlyMixin } from '@/mixins/numberOnly';
import smoothFocus from '@/mixins/smoothFocus';
import title from '@/mixins/title';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueClickAway from 'vue3-click-away';

import '@/plugins/vee-validate';

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@/design/tailwind.css';
import '@/design/layout.css';
import '@/design/comm.css';
import '@/design/m_style.css';
import VueSweetalert2 from '@/plugins/VueSweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueClickAway);
app.use(PerfectScrollbar);
app.use(VueSweetalert2);
app.component(
  'subTitle',
  defineAsyncComponent(() => import('@components/TheSubTitle.vue'))
);

const emitter = mitt();

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.axios = axios;
app.config.globalProperties.axiosReq = axiosReq;

app.config.errorHandler = (err) => {
  if (typeof err === 'string') {
    console.error(err);
    alert(err);
  } else if (err instanceof Error) {
    console.error(err);
    alert(err.message);
  }
};

app.mixin(numberOnlyMixin);
app.mixin(smoothFocus);
app.mixin(title);

router.isReady().then(() => app.mount('#app'));
