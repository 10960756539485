<template>
  <header>
    <h1 :class="$route.path === '/main' || `S`">
      <router-link to="/main">
        <img :src="require(`@assets/images/logo.png`)" alt="씨앤에이논술" />
      </router-link>
    </h1>
    <ul>
      <li v-for="(nav, index) in navs" :key="index">
        <a
          v-if="nav.visible"
          :class="$route.path.startsWith(nav.pageUrl + '/') ? 'on' : ''"
          class="cursor-pointer"
          @click="move(nav.path)">
          {{ nav.name }}
        </a>
      </li>
      <!--      <li>-->
      <!--        <a href="#"><img :src="require(`@assets/images/all_m.png`)" alt="전체메뉴" /></a>-->
      <!--      </li>-->
    </ul>
    <div class="logBox">
      <ul>
        <li>
          <router-link to="/mypage/note/list">{{ uncheckNote }}</router-link>
        </li>
        <li>
          <a class="cursor-pointer" @click="logout"><em>로그아웃</em></a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import Stomp from 'webstomp-client';
import SockJS from 'sockjs-client';
import tokenUtil from '@utils/tokenUtil.js';

export default {
  name: 'TheHeader',
  props: {
    navs: Array,
    selectNav: Number,
  },
  data() {
    return {
      uncheckNote: 0,
      beforeUnmount: null,
    };
  },
  mounted() {
    this.connect();
    this.getUncheckNote();
    this.emitter.on('firstReadMemo', (count = 1) => {
      if (this.uncheckNote - count < 0) {
        this.uncheckNote = 0;
        return;
      }
      this.uncheckNote -= count;
    });
    this.emitter.on('deleteUncheckMemo', (count = 1) => {
      if (this.uncheckNote - count < 0) {
        this.uncheckNote = 0;
        return;
      }
      this.uncheckNote -= count;
    });
  },
  beforeUnmount() {
    if (this.stompClient != null) {
      this.stompClient.disconnect();
    }
  },
  methods: {
    getUncheckNote() {
      this.axios.get('/api/memo/list/not-read').then((res) => {
        if (res.data?.resultData) {
          this.uncheckNote += res.data.resultData.length;
        }
      });
    },
    connect() {
      const state = this.$store.getters['userStore/getToken'];
      if (tokenUtil.expirationCheck(state)) {
        console.log('Refresh Token Expiration Check : true');
        setTimeout(() => {
          this.connect();
        }, 60000);
        return;
      }

      const getToken = tokenUtil.makeTokenHeaders(state);
      const socket = new SockJS('/ws');
      const stompClient = Stomp.over(socket);
      stompClient.connect(
        {
          'jwt-access-token': getToken['jwt-access-token'],
          'jwt-refresh-token': getToken['jwt-refresh-token'],
        },
        () => this.onConnect(stompClient),
        this.onError
      );
    },
    onConnect(stompClient) {
      stompClient.subscribe(`/sub/note/${this.$store.state.userStore.email}`, () => {
        this.uncheckNote += 1;
      });
      this.stompClient = stompClient;
    },
    onError(error) {
      console.log('소켓 연결 실패', error);
      setTimeout(() => {
        this.connect();
      }, 60000);
    },
    logout() {
      if (!window.confirm('로그아웃 하시겠습니까?')) {
        return;
      }
      this.$store.commit('userStore/logout');
      this.$router.push('/login');
    },
    move(link) {
      this.$router.push(link);
      this.emitter.emit('clearCache');
    },
  },
};
</script>

<style></style>