import Home from '@/router/views/Home.vue';

export default {
  path: '/order-management',
  name: 'order-management',
  component: Home,

  children: [
    {
      path: 'order/list',
      component: () => import('@components/order-management/OrderList.vue'),
    },
    {
      path: 'giveaway/list',
      component: () => import('@components/order-management/giveaway/GiveawayList.vue'),
    },
    {
      path: 'giveaway/view/:id',
      component: () => import('@components/order-management/giveaway/GiveawayView.vue'),
    },
    {
      path: 'giveaway/write',
      component: () => import('@components/order-management/giveaway/GiveawayWrite.vue'),
    },
    {
      path: 'order/view/:id',
      component: () => import('@components/order-management/OrderView.vue'),
    },
    {
      path: 'settle/list',
      component: () => import('@components/order-management/settle/SettleList.vue'),
    },
    {
      path: 'settle/view/:id',
      component: () => import('@components/order-management/settle/SettleView.vue'),
    },
    {
      path: 'book-movement',
      component: () => import('@components/order-management/book-movement/BookMovement.vue'),
    },

    {
      path: 'order-ledger', //주문대장
      component: () => import('@components/order-management/sales-status/SalesStatus.vue'),
    },
    {
      path: 'regist-new-book',
      component: () => import('@components/order-management/book/RegistNewBook.vue'),
      meta: { mainComponent: '/order-management/new-book' },
    },
    {
      path: 'new-book',
      component: () => import('@components/order-management/book/NewBook.vue'),
    },

    {
      path: 'book',
      component: () => import('@components/order-management/book/Book.vue'),
    },
    {
      path: 'rental-info',
      component: () => import('@components/order-management/rental/BookRentalInfo.vue'),
    },
    {
      path: 'rental/book-rental/write',
      component: () => import('@components/order-management/rental/BookRental.vue'),
    },
    {
      path: 'rental/book-rental/list', //도서 대여관리
      component: () => import('@components/order-management/rental/bookRentalList.vue'),
      //component: () => import('@components/order-management/BookRental.vue'),
    },
    {
      path: 'rental/book-rental-admin/list', //도서 대여관리
      component: () => import('@components/order-management/rental/bookRentalListAdmin.vue'),
      //component: () => import('@components/order-management/BookRental.vue'),
    },
    {
      path: 'rental/rental-info-admin',
      component: () => import('@components/order-management/rental/BookRentalInfoAdmin.vue'),
    },
    {
      path: 'rental/my-rental',
      component: () => import('@components/order-management/rental/BookRentalInfo.vue'),
    },
    // {
    //   path: 'order-list',
    //   component: () => import('@comp/order/order-list.vue'),
    // },
    {
      path: 'branch/list',
      component: () => import('@components/order-management/branch/BranchList.vue'),
    },
    {
      path: 'branch/view/:id',
      component: () => import('@components/order-management/branch/BranchView.vue'),
    },
  ],
};
