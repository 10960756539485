import Home from '@/router/views/Home.vue';

export default {
  path: '/counsel',
  name: 'counsel',
  component: Home,
  children: [
    {
      path: 'student-state/write',
      component: () => import('@components/counsel/StudentStateWrite.vue'),
    },
    // {
    //   path: 'student-state/list',
    //   component: () => import('@components/management-board/StudentStateList.vue'),
    // },

    // {
    //   path: 'student-state/write/:id',
    //   component: () => import('@components/management-board/StudentStateWrite.vue'),
    // },

    {
      path: 'work-report/list',
      component: () => import('@components/counsel/work_report/WorkReportList.vue'),
    },
    {
      path: 'work-report/view/:id',
      component: () => import('@components/counsel/work_report/WorkReportView.vue'),
    },
    {
      path: 'work-report/write',
      component: () => import('@components/counsel/work_report/WorkReportWrite.vue'),
    },
    {
      path: 'work-report/write/:id',
      component: () => import('@components/counsel/work_report/WorkReportWrite.vue'),
    },
    {
      path: 'student-statistics',
      component: () => import('@components/counsel/StudentStatistics.vue'),
    },
    {
      path: 'timetable',
      component: () => import('@components/counsel/Timetable.vue'),
    },
    {
      path: 'class-status',
      component: () => import('@components/counsel/ClassStatus.vue'),
    },
  ],
};
