<template>
  <div class="leftWrap">
    <div class="idM mgb20">
      <a class="proF" href="#"><i>아이콘</i></a>
      {{ $store.state.userStore.name }}
      <a class="set" href="#">
        <img :src="require(`@assets/images/ico_set.gif`)" alt="설정" @click="$router.push('/mypage/my-info-modify')"/>
      </a>
      <a class="userlist cursor-pointer" @click="$router.push('/mypage/user-list')">C&amp;A 명부</a>
    </div>

    <div class="calendar">
      <div class="month">
        <a class="mr-1.5" @click="provMonth">
          <img :src="require(`@assets/images/btn_prevM.gif`)" alt="이전달"/>
        </a>
        <span class="monC mr-1">{{ month + 1 }}</span>
        <a @click="nextMonth">
          <img :src="require(`@assets/images/btn_nextM.gif`)" alt="다음달"/>
        </a>
        <span class="year">{{ year }}</span>
      </div>
      <div class="day">
        <span v-for="value in days" :key="value" :class="[value.class]">
          <em>
            {{ value.date }}
          </em>
        </span>
      </div>
    </div>
    <div v-if="userGroup === 'DIRECT_MANAGEMENT' || userGroup === 'HEAD_OFFICE'" class="WeekPass">
      <h3>주간패스워드</h3>
      <ul v-if="password">
        <li>
          이번주 : <em>{{ password }}</em>
        </li>
        <li>
          다음주 : <em>{{ nextPassword }}</em>
        </li>
      </ul>
      <ul v-else>
        <li>이번주 : <em>조회중</em></li>
        <li>다음주 : <em>조회중</em></li>
      </ul>
      <p>로그인시 "주간패스워드+마이패스워드"를 입력하세요.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheCalender',
  data() {
    return {
      month: '',
      year: '',
      today: 0,
      days: [],
      password: '',
      nextPassword: '',
    };
  },
  mounted() {
    this.calenderInit();
    this.getWeekPassword();
  },
  computed: {
    userGroup() {
      return this.$store.getters['userStore/getUserGroup'];
    },
  },
  methods: {
    getWeekPassword() {
      this.axios.get('/api/week-password').then((res) => {
        this.password = res.data?.resultData.password;
        this.nextPassword = res.data?.resultData.nextPassword;
      });
    },
    calenderInit() {
      const today = new Date();
      this.year = today.getFullYear();
      this.month = today.getMonth();
      this.days = this.setCalendarData(this.year, this.month);
    },
    provMonth() {
      const probDate = new Date(this.year, this.month - 1);
      this.year = probDate.getFullYear();
      this.month = probDate.getMonth();
      this.days = this.setCalendarData(this.year, this.month);
    },
    nextMonth() {
      const nextDate = new Date(this.year, this.month + 1);
      this.year = nextDate.getFullYear();
      this.month = nextDate.getMonth();
      this.days = this.setCalendarData(this.year, this.month);
    },
    setCalendarData(year, month) {
      const calHtml = [];
      const today = new Date();
      const setDate = new Date(year, month, 1);
      const firstDayName = setDate.getDay();

      const lastMonthSunday = new Date(year, month, 0 - (firstDayName - 1));
      const lastMonthDate = new Date(year, month, 0);
      const lastMonth = lastMonthDate.getMonth();
      if (firstDayName !== 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = lastMonthSunday.getDate(); i <= lastMonthDate.getDate(); i++) {
          calHtml.push({ date: i, month: lastMonth });
        }
      }

      const firstWeeksLastDay = 7 - calHtml.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= firstWeeksLastDay; i++) {
        calHtml.push({ date: i, month });
      }

      const lastDay = new Date(year, month + 1, 0).getDate();

      // eslint-disable-next-line no-plusplus
      for (let i = firstWeeksLastDay + 1; i <= lastDay; i++) {
        calHtml.push({ date: i, month });
      }

      const nextMonthFirstWeeksLastDay = 42 - calHtml.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= nextMonthFirstWeeksLastDay; i++) {
        calHtml.push({ date: i, month: new Date(year, month + 1, 1).getMonth() });
      }

      return calHtml.map((data, index) => {
        const classList = [];
        if (index % 7 === 0) {
          classList.push('text-cna-orange');
        } else if ((index + 1) % 7 === 0) {
          classList.push('text-sky-600');
        } else {
          classList.push('text-gray-600');
        }

        if (index < lastMonthDate.getDate() - (lastMonthSunday.getDate() - 1)) {
          classList.push('text-opacity-50');
        }

        if (index > 42 - nextMonthFirstWeeksLastDay - 1) {
          classList.push('text-opacity-50');
        }

        if (data.date === today.getDate() && data.month === today.getMonth()) {
          classList.push('on');
          classList.push('text-white');
        }

        return {
          date: data.date,
          month: data.month,
          class: classList,
        };
      });
    },
  },
};
</script>

<style scoped>
.WeekPass {
  padding: 20px;
}

.WeekPass h3 {
  font-size: 0.98rem;
  line-height: 40px;
}

.WeekPass ul {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  height: 44px;
  margin-bottom: 10px;
}

.WeekPass ul li {
  float: left;
  width: 50%;
  font-size: 0.9rem;
  text-align: center;
}

.WeekPass ul li em {
  background: #f3f3f3;
  display: inline-block;
  padding: 3px 15px;
  border-radius: 30px;
  color: #172b4d;
  font-weight: 500;
}

.WeekPass ul li:first-child {
  border-right: 1px solid #ddd;
}

.WeekPass p {
  font-size: 0.85rem;
  color: #666;
}

.idM .userlist {
  position: absolute;
  top: 60px;
  right: 20px;
  display: inline-block;
  font-size: 0.9rem;
  color: #fff;
  width: 140px;
  line-height: 35px;
  border-radius: 50px;
  padding-left: 50px;
  background:#ff8a6a url('~@assets/images/ico_user2.png') 20px 50% no-repeat;
}

</style>