import store from '@/store/index';
import tokenUtil from '@utils/tokenUtil';

function requireAuthLogin(to, from, next) {
  // 로그인 된 상태면
  const state = store.getters['userStore/getToken'];
  if (!tokenUtil.expirationCheck(state)) {
    if (from?.path?.includes('/m/') || to?.path?.includes('/m/')) {
      next('/m/main');
    } else {
      next('/main');
    }
  }
  next();
}

function requireAuthContent(to, from, next) {
  // 로그인 안되었으면
  const state = store.getters['userStore/getToken'];
  if (tokenUtil.expirationCheck(state)) {
    if (state['jwt-access-token'] && state['jwt-refresh-token']) {
      alert('리프레시 토큰이 만료되어 로그아웃되었습니다.\n다시 로그인해 주세요.');
    }
    console.log(from);
    if (from?.path?.includes('/m/') || to?.path?.includes('/m/')) {
      next('/m/login');
    } else {
      next('/login'); // 로그인 페이지로
    }
  } else if (to.path === '/') {
    next('/main');
  } else if (to.path === '/main') {
    next();
  } else if (to.path === '/m') {
    next('/m/main');
  } else if (to.path === '/m/main') {
    next();
  } else {
    console.log(to.path);
    // let match = to.matched[to.matched.length - 1].path;
    // let paramIndex = match.lastIndexOf('/:');
    // if (paramIndex > -1) {
    //  match = match.substring(0, paramIndex);
    // }

    let path = to.path;
    path = path.charAt(path.length - 1) === '/' ? path.substr(0, path.length - 1) : path;
    console.log(path);
    let pathList = path.split('/');
    pathList = pathList.slice(1, pathList.length);

    const nav = store.getters['userStore/getNav'];
    const find = findUrl(nav, pathList);
    console.log(find);
    if (!find) {
      alert('조회 권한이 없습니다.');
      next(from.path);
    } else {
      next();
    }
  }
}

function findUrl(pageTree, pathList, index = 0) {
  for (const path of pageTree) {
    if ((path.pageUrl + '/').startsWith(`/${pathList.slice(0, index + 1).join('/')}/`)) {
      if (path.child) {
        return findUrl(path.child, pathList, index + 1);
      }
      if (`/${pathList.join('/')}`.startsWith(path.pageUrl)) {
        return true;
      }
    }
  }
  return false;
}

function requireGlobal(to, from, next) {
  const loginPath = ['/login', '/m/login', '/join', '/join-success', '/find-id', '/find-password'];
  if (loginPath.includes(to.path)) {
    requireAuthLogin(to, from, next);
  } else {
    requireAuthContent(to, from, next);
  }
  next();
}

export { requireGlobal };
