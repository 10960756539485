const path = {
  namespaced: true,
  state: {
    path: [],
    pathName: [],
  },
  mutations: {
    changePath: function (state, payload) {
      let pathList = payload.path.split('/');
      pathList = pathList.slice(1, pathList.length);
      state.path = pathList;
      state.pathName = findPathName(payload.nav, pathList);
    },
  },
  getters: {
    getPath(state) {
      return state.path;
    },
    getPathName(state) {
      return state.pathName;
    },
  },
};

function findPathName(pageTree, pathList, index = 0) {
  for (let path of pageTree) {
    if (path.pageUrl === '/' + pathList.slice(0, index + 1).join('/')) {
      if (path.child) {
        return [path.name, ...findPathName(path.child, pathList, index + 1)];
      } else {
        if (('/' + pathList.join('/')).startsWith(path.pageUrl)) {
          return [path.name];
        }
      }
    }
  }
  return [];
}

export default path;
