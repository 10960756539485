const suffix = '씨엔에이논술';

export default {
  data() {
    return {pageTitle: ''};
  },
  created() {
    this.pageTitle = this.pageTitle = this.$store.getters['path/getPathName'][1];
  },
  watch: {
    $route() {
      this.pageTitle = this.pageTitle = this.$store.getters['path/getPathName'][1];
    },
    pageTitle: function () {
      this.changeTitle(this.pageTitle);
    },
  },
  methods: {
    changeTitle(to) {
      document.title = to ? `${to} < ${suffix}` : suffix;
    },
  },
};
