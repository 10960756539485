export default [
  {
    path: '/m/login',
    name: 'MobileLogin',
    component: () => import('@/router/mobile-views/member/Login.vue'),
  },
  {
    path: '/m',
    name: 'MobileHome',
    component: () => import('@/router/mobile-views/Home.vue'),
    children: [
      {
        path: 'main',
        component: () => import('@mobiles/MobileMain.vue'),
      },
      {
        path: 'board',
        name: 'MobileBoard',
        children: [
          {
            path: ':boardName/list',
            name: 'MobileNotices',
            component: () => import('@mobiles/board/BoardList.vue'),
          },
          {
            path: ':boardName/view/:id',
            name: 'MobileNoticesView',
            component: () => import('@mobiles/board/BoardView.vue'),
          },
          // {
          //   path: 'study/list',
          //   name: 'MobileStudy',
          //   component: () => import('@mobiles/board/studyList.vue'),
          // },
          // {
          //   path: 'study/view',
          //   name: 'MobileStudyView',
          //   component: () => import('@mobiles/board/studyView.vue'),
          // },
        ],
      },
      {
        path: 'order-management',
        name: 'MobileOrderManagement',
        children: [
          {
            path: 'order/list',
            name: 'MobileManagementOrderList',
            component: () => import('@mobiles/order-manage/OrderList.vue'),
          },
          {
            path: 'order/view/:id',
            name: 'MobileManagementOrderView',
            component: () => import('@mobiles/order-manage/OrderView.vue'),
          },
          {
            path: 'settle/list',
            name: 'MobileSettleList',
            component: () => import('@mobiles/order-manage/SettleList.vue'),
          },
          {
            path: 'settle/view/:id',
            name: 'MobileSettleView',
            component: () => import('@mobiles/order-manage/SettleView.vue'),
          },
        ],
      },
      {
        path: 'order',
        name: 'MobileOrder',
        children: [
          {
            path: 'order-history/list',
            name: 'MobileOrderList',
            component: () => import('@mobiles/order/OrderDetails.vue'),
          },
          {
            path: 'order-history/view/:id',
            name: 'MobileOrderView',
            component: () => import('@mobiles/order/OrderDetailsView.vue'),
          },
          {
            path: 'returns/list',
            name: 'MobileReturnList',
            component: () => import('@mobiles/order/Return.vue'),
          },
          {
            path: 'returns/view/:id',
            name: 'MobileReturnView',
            component: () => import('@mobiles/order/ReturnView.vue'),
          },
        ],
      },
    ],
  },
];
