import { configure, defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

defineRule('positive', (value) => {
  if (typeof value === 'number') {
    if (value > 0) {
      return true;
    }
  } else if (Number(value) > 0) {
    return true;
  }

  return false;
});

defineRule('nonempty', (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  return value !== '';
});

defineRule('phone', (value, []) => {
  if (value) {
    return checkPhone(value);
  }
  return true;
});

export function checkPhone(str) {
  if (str.length === 0) {
    return false;
  }

  let value = str.replace(/[^0-9]/gi, '');
  let seoul = value.charAt(1) === '2';
  if (seoul ? value.length < 9 || value.length > 10 : value.length < 10 || value.length > 11) {
    return false;
  }

  if (seoul ? !checkPhone1(value.substr(0, 2)) : !checkPhone1(value.substr(0, 3))) {
    return false;
  }
  if (
      seoul ? !checkPhone2(value.substr(2, value.length === 9 ? 3 : 4)) : !checkPhone2(
          value.substr(3, value.length === 10 ? 3 : 4))
  ) {
    return false;
  }
  if (
      seoul ? !checkPhone3(value.substr(value.length === 9 ? 5 : 6, 4)) : !checkPhone3(
          value.substr(value.length === 10 ? 6 : 7, 4))
  ) {
    return false;
  }

  return true;
}

export function checkPhone1(str) {
  if (str.length == 0) {
    return false;
  }
  return true;
}

export function checkPhone2(str) {
  if (str.length == 0) {
    return false;
  }

  if (str.length < 3 || str.length > 4) {
    return false;
  }
  return true;
}

export function checkPhone3(str) {
  if (str.length == 0) {
    return false;
  }

  if (str.length != 4) {
    return false;
  }
  return true;
}

defineRule('address', (value, [], ctx) => {
  if (ctx.form?.address?.address_etc || ctx.form?.address?.zipcode) {
    return !!value;
  } else {
    return true;
  }
});
defineRule('address_etc', (value, [], ctx) => {
  if (ctx.form?.address?.address || ctx.form?.address?.zipcode) {
    return !!value;
  } else {
    return true;
  }
});
defineRule('zipcode', (value, [], ctx) => {
  if (ctx.form?.address?.address || ctx.form?.address?.address_etc) {
    return !!value;
  } else {
    return true;
  }
});

// function isEmpty(value) {
//   if (value === null || value === undefined || value === '') {
//     return true;
//   }
//   if (Array.isArray(value) && value.length === 0) {
//     return true;
//   }
//   return false;
// }
//
// const ar = /^[٠١٢٣٤٥٦٧٨٩]+$/;
// const en = /^[0-9]+$/;
// const numericValidator = value => {
//   if (isEmpty(value)) {
//     return true;
//   }
//   const testValue = val => {
//     const strValue = String(val);
//     return en.test(strValue) || ar.test(strValue);
//   };
//   if (Array.isArray(value)) {
//     return value.every(testValue);
//   }
//   return testValue(value);
// };

configure({
  generateMessage: localize({
    ko: {
      code: 'ko',
      messages: {
        alpha: '{field}에는 영문자만 사용 가능합니다',
        alpha_dash: '{field}에는 영문자, 숫자와 특수기호(-),(_)만 사용 가능합니다',
        alpha_num: '{field}에는 영문자와 숫자만 사용 가능합니다',
        alpha_spaces: '{field}에는 영문자와 공백만 사용 가능합니다',
        between: '{field}의 값은 0:{min}에서 1:{max} 사이여야 합니다',
        confirmed: '{field}의 값이 일치하지 않습니다',
        digits: '{field}의 값은 0:{length}자리의 숫자이어야 합니다',
        dimensions: '{field}의 크기는 가로 0:{width}픽셀, 세로 1:{height}픽셀이어야 합니다',
        email: '{field}의 값은 유효한 이메일 형식이어야 합니다',
        excluded: '{field}은/는 유효한 값이어야 합니다',
        ext: '{field}은/는 유효한 파일이어야 합니다',
        image: '{field}은/는 이미지 파일이어야 합니다',
        integer: '{field}의 값은 정수이어야 합니다',
        length: '{field}의 값은 0:{length}자이어야 합니다',
        max_value: '{field}의 값은 0:{max} 이하이어야 합니다',
        max: '{field}의 값은 최대 0:{length}글자이어야 합니다',
        mimes: '{field}은/는 유효한 파일 형식의 파일이어야 합니다',
        min_value: '{field}의 값은 0:{min} 이상이어야 합니다',
        min: '{field}의 값은 최소 0:{length}글자이어야 합니다',
        numeric: '{field}에는 숫자만 사용 가능합니다',
        one_of: '{field}의 값은 유효한 값이어야 합니다',
        regex: '{field}은/는 형식에 맞지 않습니다',
        required: '{field}을/를 입력해 주세요',
        required_if: '{field}은/는 필수 정보입니다',
        size: '{field}의 크기는 0:{size}KB보다 작아야 합니다',
        positive: '{field}의 값은 양수이어야 합니다',
        nonempty: '{field}을/를 입력해 주세요',

        phone: '올바른 {field}를 입력해 주세요\n(숫자만 입력)',
        address: '올바른 주소를 입력해 주세요',
        address_etc: '올바른 상세주소를 입력해 주세요',
        zipcode: '올바른 우편번호를 입력해 주세요',
      },
      names: {
        email: '이메일',
        bookName: '교재명',
      },
    },
  }),
});

setLocale('ko');
