import { deepFreeze } from '@utils/objectUtil';

const imageFileWhitelist = {
  extension: [
    //==이미지==//
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'tif',
    'tiff',
  ],
  mediaTypes: [
    //==이미지==//
    'image/bmp', // bmp
    'image/gif', // gif
    'image/jpeg', // jpg, jpeg
    'image/png', // png
    'image/tiff', // tif, tiff
  ],
};

const documentFileWhitelist = {
  extension: [
    'hwp', // 한컴 파일
    'hwt', // 한컴 파일
    'hml', // 한컴 파일
    'hwpx', // 한컴 파일

    'doc', // ms office word 파일
    'docm', // ms office word 파일
    'docx', // ms office word 파일
    'dotx', // ms office word 파일
    'dotm', // ms office word 파일

    'potm', // ms office ppt 파일
    'potx', // ms office ppt 파일
    'ppam', // ms office ppt 파일
    'pps', // ms office ppt 파일
    'ppsx', // ms office ppt 파일
    'ppsm', // ms office ppt 파일
    'ppt', // ms office ppt 파일
    'pptm', // ms office ppt 파일
    'pptx', // ms office ppt 파일

    'xlsx', // ms office excel 파일
    'xlsb', // ms office excel 파일
    'xls', // ms office excel 파일
    'xlsm', // ms office excel 파일

    'html', // html
    'pdf', // pdf문서
    'csv', // cvs 파일
    'csv1', // cvs 파일

    // adobe
    'ai',
    'psd',
    'indd',

    // 음성
    'mp3',
    'aac',
    'wma',
    'wav',

    // 영상
    'mp4',
    'wmv',
    'avi',
    'mov',
    'flv',
    'f4v',
    'mkv',
    'swf',
    'ts',
    'tsv',
    'tsa',
    'm2t',
  ],
  mediaTypes: [
    'application/x-hwp', // hwp
    'application/haansofthwp', // hwp
    'application/vnd.hancom.hwp', // hwp
    'application/x-hwt', // hwt
    'application/haansofthwt', // hwt
    'application/vnd.hancom.hwt', // hwt
    'application/vnd.hancom.hml', // hml
    'application/haansofthml', // hml
    'application/vnd.hancom.hwpx', // hwpx

    'application/msword', // doc
    'application/vnd.ms-word.document.macroEnabled.12', //docm
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template', //dotx
    'application/vnd.ms-word.template.macroEnabled.12', //dotm

    'application/vnd.ms-powerpoint.template.macroEnabled.12', //potm
    'application/vnd.openxmlformats-officedocument.presentationml.template', //potx
    'application/vnd.ms-powerpoint.addin.macroEnabled.12', //ppam
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow', // pps
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow', //ppsx
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', //ppsm
    'application/vnd.ms-powerpoint', // ppt
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12', //pptm
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', //pptx

    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //xlsx
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12', //xlsb
    'application/vnd.ms-excel', // xls
    'application/vnd.ms-excel.sheet.macroEnabled.12', //xlsm

    'text/html', //html
    'application/pdf', // pdf
    'text/csv', // csv
    'application/csv', //csv1
  ],
};

const fileWhitelist = {
  extension: [
    //==기타==//
    'txt',
    'zip',
    'rtf',
  ],
  mediaTypes: [
    //==기타==//
    'text/plain', // txt
    'application/zip', // zip
    'application/x-zip-compressed',
    'application/x-zip',
  ],
};

fileWhitelist.extension.push(...imageFileWhitelist.extension);
fileWhitelist.mediaTypes.push(...imageFileWhitelist.mediaTypes);
fileWhitelist.extension.push(...documentFileWhitelist.extension);
fileWhitelist.mediaTypes.push(...documentFileWhitelist.mediaTypes);

const freezeFileWhitelist = deepFreeze(fileWhitelist);
const freezeImageFileWhitelist = deepFreeze(imageFileWhitelist);
const freezeDocumentFileWhitelist = deepFreeze(documentFileWhitelist);

export {
  freezeFileWhitelist as fileWhitelist,
  freezeImageFileWhitelist as imageFileWhitelist,
  freezeDocumentFileWhitelist as documentFileWhitelist,
};
