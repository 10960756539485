<template>
  <div class="copyR">Copyright 2021 C&A. All rights reserved.</div>
</template>

<script>
export default {
  name: 'Copyright',
};
</script>

<style></style>
