// import jwt from 'jsonwebtoken';
import * as jose from 'jose';

// 만료 여부
function expirationCheck(state) {
  if (!state['jwt-access-token'] || !state['jwt-refresh-token']) {
    return true;
  }
  const decodeAccessToken = jose.decodeJwt(state['jwt-access-token']);
  const decodeRefreshToken = jose.decodeJwt(state['jwt-refresh-token']);
  if (decodeAccessToken.exp < Date.now() / 1000 + 60) {
    if (decodeRefreshToken.exp < Date.now() / 1000 + 60) {
      return true;
    }
    return false;
  }
  return false;
}

function makeTokenHeaders(state) {
  if (!state['jwt-access-token'] || !state['jwt-refresh-token']) {
    return {
      'jwt-access-token': '',
      'jwt-refresh-token': '',
    };
  }
  const decodeAccessToken = jose.decodeJwt(state['jwt-access-token']);
  let headers = null;
  if (decodeAccessToken.exp < Date.now() / 1000 + 60) {
    headers = {
      'jwt-access-token': `Bearer ${state['jwt-access-token']}`,
      'jwt-refresh-token': `Bearer ${state['jwt-refresh-token']}`,
    };
  } else {
    headers = {
      'jwt-access-token': `Bearer ${state['jwt-access-token']}`,
    };
  }

  return headers;
}

export default { expirationCheck, makeTokenHeaders };
