<template>
  <div :class="$route.path === '/main' ? 'mainWrap' : 'outerWrap'" class="wrap">
    <TheHeader :navs="topNav" @move="clearCache" />
    <component :is="$route.path === '/main' ? 'TheCalender' : 'TheSideNav'" :navTitle="navTitle" :navs="childNav"></component>
    <div :class="$route.path === '/main' ? 'contWrap' : 'contWrapS'">
      <TheNotice></TheNotice>

      <div class="cont cancelFloat">
        <router-view v-slot="{ Component, route }">
          <!-- <keep-alive v-if="$store.getters['userStore/isLogin'] && keepCache" :max="maxCache">
            <component :is="Component" :key="route.path + routeKey" />
          </keep-alive> -->
          <component :is="Component" :key="route.path" />
        </router-view>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Copyright from '@components/Copyright.vue';
import TheHeader from '@components/TheHeader.vue';
import TheSideNav from '@components/TheSideNav.vue';
import TheCalender from '@components/TheCalender.vue';
import TheNotice from '@components/TheNotice.vue';

export default {
  name: 'Home',
  data() {
    return {
      selectNav: 0,
      topNav: [],
      childNav: [],
      keepCache: true,
      routeKey: 0,
      maxCache: 20,
      navTitle: '',
    };
  },
  watch: {
    $route() {
      this.pathFind();
    },
  },
  created() {
    this.clearPath();
  },
  mounted() {
    this.clearPath();
  },
  methods: {
    clearPath() {
      this.topNav = this.$store.state.userStore.navigator;
      this.pathFind();
      this.emitter.on('clearCache', this.clearCache);
    },
    pathFind() {
      const payload = { path: this.$route.path, nav: this.$store.getters['userStore/getNav'] };
      this.$store.commit('path/changePath', payload);

      const { path } = this.$route;
      this.topNav.forEach((nav) => {
        if (path.startsWith(`${nav.pageUrl}/`)) {
          this.childNav = nav.child;
          this.navTitle = nav.name;
        }
      });
      console.log({ Nav: this.childNav });
    },
    clearCache() {
      this.maxCache = 1;
      this.routeKey++;
      this.maxCache = 20;
    },
  },
  components: {
    TheHeader,
    TheSideNav,
    TheCalender,
    TheNotice,
    Copyright,
  },
};
</script>

<style scoped>
.mainWrap {
  background: #f3f5f6 url('~@assets/images/left_bgMain.jpg') 0 0 repeat-y;
}

.outerWrap {
  background: #f3f5f6 url('~@assets/images/left_bg.jpg') 0 0 repeat-y;
}
</style>