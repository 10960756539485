import Axios from 'axios';
import tokenUtil from '@utils/tokenUtil.js';
import store from '@/store/index';

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
  (config) => {
    const getToken = tokenUtil.makeTokenHeaders(store.getters['userStore/getToken']);

    config.headers['jwt-access-token'] = getToken['jwt-access-token'];
    config.headers['jwt-refresh-token'] = getToken['jwt-refresh-token'];

    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default axios;
