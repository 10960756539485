export default [
  {
    path: 'message/sms',
    name: 'MypageSms',
    component: () => import('@components/mypage/MypageSmsWrite.vue'),
  },
  {
    path: 'message/sms-list',
    name: 'MypageSmsList',
    component: () => import('@/components/mypage/MypageSmsList.vue'),
  },
];
