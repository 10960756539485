const numberOnlyMixin = {
  methods: {
    numberOnly(value) {
      return String(value).replace(/[^0-9]/gi, '');
    },
    numberOnlyNegativePossible(value) {
      let str = value.replace(/[^-0-9]/gi, '');

      if (str.lastIndexOf('-') > 0) {
        if (str.indexOf('-') === 0) {
          str = '-' + str.replace(/[-]/gi, '');
        } else {
          str = str.replace(/[-]/gi, '');
        }
      }

      return str;
    },
  },
};

function numberOnlyComposition() {
  const numberOnly = value => {
    return value.replace(/[^0-9]/gi, '');
  };
  const numberOnlyNegativePossible = value => {
    let str = value.replace(/[^-0-9]/gi, '');

    if (str.lastIndexOf('-') > 0) {
      if (str.indexOf('-') === 0) {
        str = '-' + str.replace(/[-]/gi, '');
      } else {
        str = str.replace(/[-]/gi, '');
      }
    }

    return str;
  };

  return {
    numberOnly,
    numberOnlyNegativePossible,
  };
}

export { numberOnlyMixin, numberOnlyComposition };
