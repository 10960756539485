<template>
  <div class="leftWrapS">
    <div class="mIcon">
      <ul>
        <li>
          <a @click="move('/mypage/my-info-modify')" class="proF"><i>아이콘</i></a>
        </li>
        <li class="cursor-pointer">
          <a @click="move('/mypage/note/write')">
            <img :src="require(`@assets/images/ico_Lm01.png`)" alt="쪽지"/>
          </a>
        </li>
        <li class="cursor-pointer">
          <a @click="move('/mypage/message/sms')">
            <img :src="require(`@assets/images/ico_Lm03.png`)" alt="문자메세지"/>
          </a>
        </li>
        <li class="cursor-pointer">
          <a @click="move('/mypage/my-posts')">
            <img :src="require(`@assets/images/ico_Lm02.png`)" alt="내 게시글"/>
          </a>
        </li>
        <li class="cursor-pointer">
          <a @click="move('/mypage/my-info-modify')">
            <img :src="require(`@assets/images/ico_Lm04.png`)" alt="설정"/>
          </a>
        </li>
      </ul>
    </div>
    <!-- sub menu -->
    <div class="subM">
      <h2>{{ navTitle }}</h2>
      <ul class="m cursor-pointer" v-if="navs?.length">
        <template v-for="nav in navs" :key="nav">
          <li v-if="nav.visible">
            <a @click="move(nav.path)" :class="pathComparison(nav.pageUrl) ? 'on' : ''">
              {{ nav.name }}
            </a>
            <ul v-if="showChild(nav.child)">
              <template v-for="childNav in nav.child" :key="childNav">
                <li v-if="childNav.visible">
                  <a
                      @click="move(childNav.path)"
                      :class="pathComparison(childNav.pageUrl) ? 'on' : ''">
                    {{ childNav.name }}
                  </a>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheSideNav',
  props: {
    navs: Array,
    navTitle: String,
  },
  methods: {
    pathComparison(path) {
      let routePath = this.$route.path + '/'
      let matchMainComponent = false;
      if (this.$route.meta.mainComponent) {
        matchMainComponent = this.$route.meta.mainComponent.startsWith(path);
      }
      return routePath.startsWith(path + '/') || matchMainComponent;
    },
    showChild(child) {
      if (child) {
        return child.filter(child => child.visible).length > 0;
      }
      return false;
    },
    move(link) {
      this.$router.push(link);
      this.emitter.emit('clearCache');
    },
  },
};
</script>

<style></style>
