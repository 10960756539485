import Home from '@/router/views/Home.vue';
import noteRouter from '@/router/mypage/note.js';
import messageRouter from '@/router/mypage/message.js';

export default {
  path: '/mypage',
  name: 'mypage',
  component: Home,
  children: [
    {
      path: 'my-info-modify',
      component: () => import('@components/mypage/MypageInfoModify.vue'),
    },
    ...noteRouter,
    ...messageRouter,
    {
      path: 'my-posts',
      component: () => import('@components/mypage/MypageWritePost.vue'),
    },{
      path: 'user-list',
      component: () => import('@components/mypage/UserList.vue'),
    },
  ],
};
