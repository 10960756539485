import Home from '@/router/views/Home.vue';

export default {
  path: '/order',
  name: 'order',
  component: Home,

  children: [
    {
      path: 'book',
      component: () => import('@components/order/OrderBook.vue'),
    },
    {
      path: 'book/new',
      component: () => import('@components/order/OrderNewBook.vue'),
    },
    {
      path: 'list',
      component: () => import('@components/order/OrderList.vue'),
    },
    {
      path: 'view/:id',
      component: () => import('@components/order/OrderView.vue'),
    },
    {
      path: 'basket',
      component: () => import('@components/order/Basket.vue'),
    },
    {
      path: 'return/write',
      component: () => import('@components/order/ReturnWrite.vue'),
      meta: { componentAlias: 'return', mainComponent: 'return' },
    },
    {
      path: 'returns/list',
      component: () => import('@components/order/ReturnList.vue'),
      meta: { mainComponent: 'return-parent' },
    },
    {
      path: 'returns/view/:id',
      component: () => import('@components/order/ReturnView.vue'),
      meta: { mainComponent: 'return-parent' },
    },
    {
      path: 'book-movement',
      component: () => import('@components/order/BookMovement.vue'),
    },
  ],
};
