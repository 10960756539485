<template>
  <div class="conTop">
    <p v-if="notice?.id">
      <strong>NOTICE</strong>
      {{ notice.title }}
      <router-link :to="`/board/${notice.boardPath}/view/${notice.id}`">
        자세히 보기
      </router-link>
    </p>
    <p v-if="!notice?.id">
      <strong>NOTICE</strong>
      공지사항이 없습니다.
    </p>
    <span>{{ datetime }} <em>|</em> {{ $store.state.userStore.name }}님 안녕하세요.</span>
  </div>
</template>

<script>
export default {
  name: 'TheNotice',
  data() {
    return {
      notice: {},
      datetime: '',
    };
  },
  mounted() {
    this.initTime();
    this.getNotice();
  },
  watch: {
    '$route'(to) {
      if(to.path !=='/login'){
        this.getNotice();
      }
    }
  },
  methods: {
    getNotice() {
      this.axios.get('/api/board/notice/latest')
      .then(res => {
        if (res.data?.resultData) {
          this.notice = res.data.resultData[0];
        }else{
          this.notice = {};
        }
      });
    },
    initTime() {
      this.updateTime();
      const delay = new Date().getMilliseconds();
      setTimeout(() => setInterval(this.updateTime, 1000), 1000 - delay);
    },
    updateTime() {
      const datetime = new Date();

      const year = datetime.getFullYear();
      const month = this.zeroPadding(datetime.getMonth() + 1);
      const date = this.zeroPadding(datetime.getDate());
      const ampm = datetime.getHours() < 13 ? 'AM' : 'PM';
      const hours = this.zeroPadding(datetime.getHours());
      const minutes = this.zeroPadding(datetime.getMinutes());
      const seconds = this.zeroPadding(datetime.getSeconds());

      this.datetime =
          year + '-' + month + '-' + date + ' ' + ampm + ' ' + hours + ':' + minutes + ':'
          + seconds;
    },

    zeroPadding(num) {
      return num < 10 ? '0' + num : num;
    },
  },
};
</script>

<style scoped>

</style>
