import { createRouter, createWebHistory } from 'vue-router';
import { requireGlobal } from '@/router/navigation-guards/guards';

import mypageRouter from '@/router/mypage/mypage-router';
import orderManagementRouter from '@/router/order-management-router';
import orderRouter from '@/router/order-router';
import boardRouter from '@/router/board-router';
import managementBoardRouter from '@/router/management-board-router';
import intranetManagementRouter from '@/router/intranet-management-router';
import counselRouter from '@/router/counsel-router';
import mobileRouter from '@/router/mobile-router';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/router/views/member/Login.vue'),
  },
  {
    path: '/join',
    name: 'Join',
    component: () => import('@/router/views/member/Join.vue'),
  },
  {
    path: '/join-success',
    name: 'JoinSuccess',
    component: () => import('@/router/views/member/JoinSuccess.vue'),
  },
  /* {
    path: '/find-id',
    name: 'FindId',
    component: () => import('@/router/views/member/FindId.vue'),
  },
  {
    path: '/find-password',
    name: 'FindPassword',
    component: () => import('@/router/views/member/FindPassword.vue'),
  }, */
  {
    path: '/',
    name: 'Home',
    component: () => import('@/router/views/Home.vue'),
    children: [
      {
        path: 'main',
        component: () => import('@components/Main.vue'),
      },
      {
        path: '',
      },
    ],
  },
  mypageRouter,
  orderManagementRouter,
  intranetManagementRouter,
  boardRouter,
  orderRouter,
  managementBoardRouter,
  counselRouter,
  ...mobileRouter,
  {
    path: '/:pathMatch(.*)',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  requireGlobal(to, from, next);
});

export default router;
