import Axios from 'axios';
import router from '@/router/index';
import tokenUtil from '@utils/tokenUtil.js';
import store from '@/store/index';
import qs from 'qs';

const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(
    (config) => {
      const getToken = tokenUtil.makeTokenHeaders(
          store.getters['userStore/getToken']);

      config.headers['jwt-access-token'] = getToken['jwt-access-token'];
      config.headers['jwt-refresh-token'] = getToken['jwt-refresh-token'];

      config.paramsSerializer = (params) => qs.stringify(params);

      return config;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    },
);

function tokenRenewal(response) {
  const loginPayload = {};

  loginPayload['jwt-access-token'] = response.headers['jwt-access-token'];
  loginPayload['jwt-refresh-token'] = response.headers['jwt-refresh-token'];
  if (response.config.url === '/login') {
    loginPayload['resultData'] = response.data.resultData;
  }
  store.commit('userStore/updateToken', loginPayload);
}

async function getErrorMsg(error) {
  let responseData = error.response.data;
  if (
      error.response.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
  ) {
    responseData = JSON.parse(await error.response.data.text());
  }

  let alertMsg;
  if (responseData.errorMessage) {
    alertMsg = responseData.errorMessage;
  } else {
    alertMsg = '작업 처리중 오류가 발생했습니다.';
  }

  if (responseData.showStatus) {
    alertMsg += `\n[ ${error.response.status} Error ]`;
  }

  if (responseData.errorCode) {
    alertMsg += `\n[ 에러코드 : ${responseData.errorCode} ]`;
  }

  return alertMsg;
}

let count = 0;

axios.interceptors.response.use(
    (response) => {
      count = 0;
      tokenRenewal(response);
      return response;
    },
    async (error) => {
      console.error(error);

      const errorMessage = await getErrorMsg(error);

      if (count < 1 || error.response.status !== 401) {
        alert(errorMessage);
      }

      if (error.response.status === 401) {
        count += 1;
        store.commit('userStore/logout');

        if (router.currentRoute.value.fullPath?.includes('/m/')) {
          await router.push('/m/login');
        } else {
          await router.push('/login');
        }
      } else {
        count = 0;
      }

      return Promise.reject(error);
    },
);
export default axios;
