export default [
  {
    path: 'note/write',
    name: 'MypageNoteWrite',
    component: () => import('@components/mypage/MypageNoteWrite.vue'),
  },
  {
    path: 'note/list',
    name: 'MypageNoteList',
    component: () => import('@components/mypage/MypageNoteList.vue'),
  },
  {
    path: 'note/view/:id',
    component: () => import('@components/mypage/MypageNoteView.vue'),
    meta: { mainComponent: '/mypage/note/list' },
  },
  {
    path: 'note/send-list',
    name: 'MypageSendNoteList',
    component: () => import('@components/mypage/MypageSendNoteList.vue'),
  },
  {
    path: 'note/send-view/:id',
    component: () => import('@components/mypage/MypageSendNoteView.vue'),
    meta: { mainComponent: '/mypage/note/send-list' },
  },
];
