// import jwt from 'jsonwebtoken';
import * as jose from 'jose';
import tokenUtil from '@utils/tokenUtil.js';

const userStore = {
  namespaced: true,
  state: {
    accessToken: '',
    refreshToken: '',
    email: '',
    phone: '',
    name: '',
    branchType: '',
    royalty: '',
    userGroup: '',
    // adminId: '',
    // adminDept: '',
    // adminName: '',
    // adminScrGrpCd: '',
    // roleDetail: [],
    navigator: [],
    navigatorFlatChild: [],
    organizations: [],
  },
  mutations: {
    organizations(state, payload) {
      state.organizations = payload;
    },
    updateNavigator(state, payload) {
      function getAllChildPathUrl(nav) {
        return nav.flatMap((nav) => {
          if (nav?.child) {
            return [...getAllChildPathUrl(nav?.child)];
          }
          return nav.pageUrl;
        });
      }

      state.navigator = payload;
      state.navigatorFlatChild = getAllChildPathUrl(payload);
    },
    updateToken(state, payload) {
      const accessToken = payload['jwt-access-token'];
      const refreshToken = payload['jwt-refresh-token'];
      // const roleDetail = payload['resultData'];

      if (accessToken) {
        state.accessToken = accessToken;
        const decodeAccessToken = jose.decodeJwt(accessToken);
        console.log(decodeAccessToken);
        state.email = decodeAccessToken.userInfo.email;
        state.phone = decodeAccessToken.userInfo.phone;
        state.name = decodeAccessToken.userInfo.name;
        state.branchType = decodeAccessToken.userInfo.branchType;
        state.royalty = decodeAccessToken.userInfo.royalty;
        state.userGroup = decodeAccessToken.userInfo.userGroup;

        // state.adminId = decodeAccessToken.userInfo.adminId;
        // state.adminDept = decodeAccessToken.userInfo.adminDept;
        // state.adminName = decodeAccessToken.userInfo.adminName;
        // state.adminScrGrpCd = decodeAccessToken.userInfo.adminScrGrpCd;
      }

      if (refreshToken) {
        state.refreshToken = refreshToken;
      }

      /* if (roleDetail && roleDetail.length) {
        state.role_detail = roleDetail;
      } */
    },
    logout: function (state) {
      state.accessToken = '';
      state.refreshToken = '';
      state.email = '';
      state.phone = '';
      state.name = '';
      // state.adminId = '';
      // state.adminDept = '';
      // state.adminName = '';
      // state.adminScrGrpCd = '';
      // state.roleDetail = [];
      state.navigator = [];
      state.navigatorFlatChild = [];
    },
  },
  getters: {
    getToken(state) {
      return {
        'jwt-access-token': state.accessToken,
        'jwt-refresh-token': state.refreshToken,
      };
    },
    getNav(state) {
      return state.navigator;
    },
    getNavFlat(state) {
      return state.navigatorFlatChild;
    },
    getOrganizations(state) {
      return state.organizations;
    },
    isLogin(state) {
      return !tokenUtil.expirationCheck({
        'jwt-access-token': state.accessToken,
        'jwt-refresh-token': state.refreshToken,
      });
    },

    getEmail(state) {
      return state.email;
    },
    getPhone(state) {
      return state.phone;
    },
    getName(state) {
      return state.name;
    },
    getBranchType(state) {
      return state.branchType;
    },
    getRoyalty(state) {
      return state.royalty;
    },
    getUserGroup(state) {
      return state.userGroup;
    },

    // getAdminId(state) {
    //   return state.adminId;
    // },
    // getAdminScrGrpCd(state) {
    //   return state.adminScrGrpCd;
    // },
  },
};

export default userStore;
